body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Righteous";
  src: local("Righteous"),
    url("./assets/fonts/Righteous-Regular.woff") format("woff");
}

video {
  object-fit: cover !important;
}

body > iframe {
  display: none !important;
}

@keyframes breath2 {
  from {
    transform: skewX(-10deg) scaleX(1);
  }
  to {
    transform: skewX(-10deg) scaleX(0.95);
  }
}
